import { multiEntityLoading } from '../mixins/loading-states';
/**
 * @class InstallationModule
 */
const InitModule = {
  namespaced: true,
  state: {
    ...multiEntityLoading.state,
  },
  mutations: {
    ...multiEntityLoading.mutations,
  },
  actions: {
    ...multiEntityLoading.actions,
    async loadInit({ dispatch }) {
      try {
        dispatch('sports/loadSports', {}, { root: true });
        dispatch('sports/loadCategories', {}, { root: true });
        dispatch('tours/loadNewestTours', {}, { root: true });
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    ...multiEntityLoading.getters,
  },
};

export default InitModule;
