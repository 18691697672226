export default {
  email: 'E-Mail',
  firstname: 'Firstname',
  lastname: 'Lastname',
  password: 'Password',
  enter_personal_information:
    'Please enter your information to create an account and be safer in the mountains.',
  login: 'Login',
  no_account: 'Register here!',
  data_policy: 'By signing up, you are agree with our terms and conditions',
  register_title: 'Sign up',
  to_login: 'Login here!',
  login_title: 'Sign in',
  enter_login_information: 'Please enter Email and password to sign in.',
  create_your_mountain_profile: 'Create your mountain profile',
  search_new_tours: 'Search for new tours',
  cant_add_yourself: 'You cant add yourself!',
  is_already_friend: 'You are already friends',
  or: 'or',
  has_friend_request: 'Friend request does exist already',
  addFriend: 'Add a friend',
  friend_name: 'Friend´s name',
  send_request: 'Send request',
  added_to_tour: 'Added to tour',
  create_group: 'Create a group',
  group_name: 'Group´s name',
  friends: 'Friends',
  requests: 'Requests',
  pending: 'Pending',
  new: 'New',
  create: 'Create',
  short_desc: 'Short description',
  prop: 'Property',
  directions: 'Directions',
  open_in_maps: 'Open in maps',
  please_wait: 'Please wait',
  choose_companions: 'Choose companions',
  summary: 'Summary',
  start_your_tour_planning: 'Start planning your tour',
  mountainprofiles: 'Mountainprofiles',
  date: 'Date',
  home: 'Home',
  lets_walk: 'Let´s walk',
  companions: 'Companions',
  groups: 'Groups',
  my_tours: 'My tours',
  profile: 'Profile',
  to_my_profiles: 'To my profiles',
  to_my_friends: 'To my friends',
  select_photo: 'Select photo',
  gallery: 'Gallery',
  take_photo: 'Take a picture',
  cancel: 'Cancel',
  photo_updated: 'Photo updated',
  start_tour_planning: 'Start planning the tour',
  bookmarks: 'Bookmarks',
  newest_tours: 'Newest tours',
  to_register: 'To register',
  added_to_bookmarks: 'Added to bookmarks',
  choose_sports: 'Choose sports',
  result: 'Result',
  temperature: 'Temperature',
  sky: 'Sky',
  tour_planning: 'Tour-planning',
  friends_and_groups: 'Friends and groups',
  tour_highlights: 'Tour-highlights',
  location: 'Location',
  select_date: 'Select date',
  search_tours: 'Search tours',
  sports: 'Sports',
  packing_list: 'Packing list',
  did_you_know: 'Did you know?',
  read_more: 'Read more',
  logout: 'Logout',
  to_settings: 'To Settings',
  to_personal_info: 'To personal information',
  settings: 'Settings',
  personal_info: 'Personal information',
  choose_one: 'Please choose',
  tourplanning_info: 'Please fill in the following information',
  friendCode: 'Code',
  member_since: 'Member since:',
  already_have_account: 'You already have an account? To login',
  to_login2: 'To login',
  not_have_account: 'You do not have an account?',
  to_register2: 'To register',
  friend_since: 'Friend since:',
  created_at: 'Created at:',
  add_friend_info:
    'To add a friend, you need to enter the code of the user which can be found on the profile page.',
  friend_code: 'Friend code',
  request_sent: 'Request sent',
  no_user_with_code: 'There is no user with this code.',
  create_group_info:
    'To create a group, you need to enter a group name and select atleast one friend.',
  enter_name: 'Please enter a group name',
  select_friend: 'Please select atleast one friend',
  notifications: 'Notifications',
  no_news: 'No notifications',
  edit_group: 'Edit group',
  edit_photo: 'Edit photo',
  delete_group: 'Delete group',
  no_friends: 'No friends',
  no_groups: 'No groups',
  no_pendings: 'No pendings',
  no_requests: 'No requests',
  declined_friend: 'Declined request',
  removed_friend: 'Removed request',
  pendings: 'Pending',
  // PARSE SERVER RESPONSE CODES
  parseserver_error_1: 'Oops, something went wrong.',
  parseserver_error_119: 'You do not have the permission to do that.',
  parseserver_error_202: 'User with this E-Mail exists already.',
  parseserver_error_201: 'Password is required',
  parseserver_error_101: 'Wrong access data',
  parseserver_error_205: 'E-Mail not verified',
  parseserver_error_142: 'Fill in all required fields',
  parseserver_error_200: 'E-Mail is required',

  valid_email_required: 'Please enter a valid email',
  firstname_required: 'Firstname is required',
  lastname_required: 'Lasntname is required',
  packing_list_modal_text: 'You should take this with you:',
  start_intro_again: 'Start intro again',
  done_walking: 'Tour abgeschlossen',
  delete_tour: 'Tour löschen',
  sure_to_delete_tour: 'Are you sure you want to delete this tour?',
  delete_consequences:
    'If you delete this tour, it will also be deleted for every other participant.',
  delete: 'Delete',
  did_like_tour: 'Did you like the tour?',
  happy_with_tour: 'Have you liked the tour?',
  yes: 'Yes',
  no: 'No',
  group_created: 'Group created',
  skip: 'skip',
  remove_bookmark: 'Removed favourite',
  add_tour: 'Added tour',
  add_friends: 'Add friends',
  horzontal_info_text: 'Expect the way to be even and you need to walk fast.',
  steps: 'steps',
  levels: 'levels',
  situation: 'Situation',
  football_fields: 'Football fields',
  rounds_400: 'Rounds',
  choose_sport: 'Choose sport',
  summary_text: 'Congratulation! Based on your answers you created the following adventureprofile',
  creat_now: 'Create adventureprofile now',
  edit_profile: 'Edit profile',
  here_since: 'User since',
  next_planned_tour: 'Next planned tour',
  no_planned_tour: 'No planned tour',
  walked_tours: 'Finished tours',
  create_new: 'Create new',
  your_friend_code: 'Your friend code',
  no_groups_yet: 'No groups yet',
  no_walked_tours: 'No walked tours',
};
