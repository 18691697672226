export default {
  email: 'E-Mail',
  firstname: 'Vorname',
  lastname: 'Nachname',
  password: 'Passwort',
  register: 'registrieren',
  enter_personal_information:
    'Bitte Informationen angeben, um einen Account zu erstellen und sicher in den Bergen unterwegs zu sein.',
  login: 'login',
  no_account: 'Hier kann Du Dich registrieren!',
  data_policy:
    'In dem Du Dich registrierst, akzeptierst du unsere AGBs und Datenschutzbestimmungen.',
  register_title: 'Registrierung',
  to_login: 'Hier kannst du dich einloggen!',
  login_title: 'Login',
  enter_login_information: 'Bitte gib deine E-Mail und Passwort ein, um dich einzuloggen.',
  create_your_mountain_profile: 'Erstelle dein Mountainprofile',
  search_new_tours: 'Entdecke neue Touren',
  or: 'oder',
  addFriend: 'Freund hinzufügen',
  friend_name: 'Name des Freundes',
  send_request: 'Anfrage senden',
  added_to_tour: 'Zur Tour hinzugefügt',
  create_group: 'Gruppe erstellen',
  group_name: 'Gruppenname',
  friends: 'Freunde',
  requests: 'Anfragen',
  pending: 'anstehend',
  new: 'neu',
  create: 'erstellen',
  short_desc: 'Kurzbeschreibung',
  prop: 'Eigenschaft',
  directions: 'Anfahrt',
  open_in_maps: 'In "Karten" öffnen',
  please_wait: 'Bitte warten',
  choose_companions: 'Begleiter auswählen',
  summary: 'Zusammenfassung',
  start_your_tour_planning: 'Mit der Planung Deiner Tour beginnen',
  mountainprofiles: 'Mountainprofile',
  date: 'Datum',
  home: 'Home',
  lets_walk: 'Lasst uns laufen',
  companions: 'Begleiter',
  groups: 'Gruppen',
  my_tours: 'Meine Touren',
  profile: 'Profil',
  to_my_profiles: 'Zu meinen Profilen',
  to_my_friends: 'Zu meinen Freunden',
  select_photo: 'Foto auswählen',
  gallery: 'Galerie',
  take_photo: 'Ein Foto aufnehmen',
  cancel: 'Abbrechen',
  photo_updated: 'Foto aktualisiert',
  start_tour_planning: 'Tourenplanung beginnen',
  bookmarks: 'Lesezeichen',
  newest_tours: 'Neuste Touren',
  to_register: 'Zur Registrierung',
  added_to_bookmarks: 'Zu Favoriten hinzugefügt',
  choose_sports: 'Sportarten auswählen',
  result: 'Ergebnis',
  temperature: 'Temperatur',
  sky: 'Himmel',
  tour_planning: 'Tourenplanung',
  friends_and_groups: 'Freunde und Gruppen',
  tour_highlights: 'Tour-Höhepunkte',
  location: 'Ort',
  select_date: 'Datum auswählen',
  search_tours: 'Nach Touren suchen',
  sports: 'Sportarten',
  packing_list: 'Packliste',
  distance: 'Distanz',
  duration: 'Dauer',
  up: 'Aufstieg',
  down: 'Abstieg',
  totalHeight: 'Gesamthöhe',
  bagpack: 'Packliste',
  has_friend_request: 'Freundesanfrage bereits vorhanden',
  is_already_friend: 'Ihr seid bereits befreundet',
  cant_add_yourself: 'Du kannst dich nicht selber als Freund hinzufügen!',
  technical_information: 'Technische Informationen',
  did_you_know: 'Schon gewusst?',
  read_more: 'Mehr erfahren',
  logout: 'Logout',
  to_settings: 'Einstellungen',
  to_personal_info: 'Persönliche Informationen',
  settings: 'Einstellungen',
  personal_info: 'Persönliche Informationen',
  choose_one: 'Bitte auswählen',
  tourplanning_info: 'Bitte folgende Daten ausfüllen.',
  friendCode: 'Code',
  member_since: 'Mitglied seit:',
  already_have_account: 'Du hast bereits einen Account?',
  to_login2: 'Zum Login',
  not_have_account: 'Du hast noch keinen Account?',
  to_register2: 'Zum Registrieren',
  friend_since: 'Freund seit:',
  created_at: 'Erstellt am:',
  add_friend_info:
    'Um einen Freund hinzuzufügen, wird der Code des Benutzers gebraucht. Diesen findet dein Freund auf seiner Profilseite',
  friend_code: 'Freundescode',
  request_sent: 'Anfrage versendet',
  no_user_with_code: 'Es gibt keinen Benutzer mit diesem Code.',
  create_group_info:
    'Um eine Gruppe zu erstellen, wird ein Gruppenname und mindestens ein Freund benötigt.',
  enter_name: 'Bitte einen Gruppennamen eingeben',
  select_friend: 'Bitte mind. einen Freund auswählen',
  notifications: 'Benachrichtigungen',
  no_news: 'Keine Benachrichtigungen',
  edit_group: 'Gruppe bearbeiten',
  edit_photo_gallery: 'Bild aus Galerie auswählen',
  edit_photo_take: 'Foto selber machen',
  delete_group: 'Gruppe löschen',
  no_friends: 'Keine Freunde',
  no_groups: 'Keine Gruppen',
  no_pendings: 'Keine angefragt',
  no_requests: 'Keine Anfragen',
  declined_friend: 'Anfrage abgelehnt',
  removed_friend: 'Anfrage entfernt',
  pendings: 'Angefragt',

  // PARSE SERVER RESPONSE CODES
  parseserver_error_1: 'Ups, etwas ist schief gelaufen.',
  parseserver_error_119: 'Die nötigen Berechtigungen fehlen für diese Aktion.',
  parseserver_error_202: 'User mit dieser E-Mail existiert bereits.',
  parseserver_error_201: 'Password wird benötigt',
  parseserver_error_101: 'Falsche Zugangsdaten',
  parseserver_error_205: 'E-Mail nicht verifiziert',
  parseserver_error_142: 'Füllen Sie benötigten Felder aus',
  parseserver_error_200: 'E-Mail wird benötigt',

  valid_email_required: 'Bitte eine gültige Email eintragen',
  firstname_required: 'Vorname wird benötigt',
  lastname_required: 'Nachname wird benötigt',
  packing_list_modal_text: 'Das sollte unbedingt dabei sein:',
  start_intro_again: 'Einführung erneut ansehen',
  done_walking: 'Tour abgeschlossen',
  delete_tour: 'Tour löschen',
  sure_to_delete_tour: 'Bist du sicher, dass diese Tour gelöscht werden soll?',
  delete_consequences:
    'Wenn du diese Tour löschst, wird diese auch für alle anderen Teilnehmer gelöscht.',
  delete: 'Löschen',
  did_like_tour: 'Hat dir die Tour gefallen?',
  happy_with_tour: 'Hat dir die Tour gefallen und warst du zufrieden mit dem Vorschlag?',
  yes: 'Ja',
  no: 'Nein',
  group_created: 'Gruppe erstellt',
  skip: 'Überspringen',
  remove_bookmark: 'Favorit entfernt',
  add_tour: 'Tour hinzugefügt',
  add_friends: 'Freunde hinzufügen',
  adventure_intro_title_1: 'Willkommen im AdventureProfile',
  adventure_intro_text_1:
    'Nimm dir doch eine Minute, um das Adventureprofile etwas genauer kennen zu lernen.',
  horzontal_info_text:
    'Gehe davon aus, dass die Strecke eben und geteert ist und du in einem zügigen Tempo gehen musst.',
  steps: 'Treppenstufen',
  levels: 'Stockwerke',
  situation: 'Situation',
  football_fields: 'Fußballfelder',
  rounds_400: 'Runden',
  choose_sport: 'Sportart wählen',
  summary_text:
    'Herzlichen Glückwunsch! Basierend auf deinen Antworten hast du folgendes Adventureprofile erstellt:',
  summary_text_1: 'ITS A MATCH',
  summary_text_2: 'Du und diese Touren: Das passt!',
  why_adventure_profile: 'Wieso brauchst du ein Adventureprofile?',
  why_adventure_profile_reason_1: 'Deine Touren werden auf dich passend zugeschnitten',
  why_adventure_profile_reason_2: 'Es ist schnell und einfach erstellt',
  why_adventure_profile_reason_3: 'Du kannst deinen Fortschritt verfolgen',
  why_adventure_profile_reason_4: 'Wir sammeln keine personenbezogenen Daten und alles ist anonym',
  adventure_profile_outro_title: 'Das wars auch schon',
  adventure_profile_outro_text: 'Danke für deine Zeit',
  creat_now: 'Jetzt Adventureprofile erstellen',
  edit_profile: 'Profil bearbeiten',
  here_since: 'Dabei seit',
  next_planned_tour: 'Nächste geplante Tour',
  no_planned_tour: 'Noch keine geplante Tour',
  walked_tours: 'Durchgeführte Touren',
  create_new: 'Neues erstellen',
  search_for_groups: 'Nach Gruppe suchen',
  search_for_friends: 'Nach Freund suchen',
  your_friend_code: 'Dein Freundescode',
  no_groups_yet: 'Keine Gruppen',
  no_walked_tours: 'Keine durchgeführten Touren',
  get_tour_on_smartphone: 'Lade Dir die Tour direkt auf dein Smartphone!',
  what_u_get: 'Das bietet Dir diese Tour',
  free: 'Kostenlos',
  no_account2: 'Kein Account nötig',
  start_now: 'Du kannst direkt los',
  description: 'Beschreibung',
  coming_soon: 'Bald verfügbar!',
  tour_details: 'Toureninformationen',
  tour_results: 'Tourenvorschläge',
  name: 'Name',
  data_privacy: 'Datenschutz',
  more: 'Mehr'
};
