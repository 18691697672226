<template>
  <div @click="handleClick" class="sport-component relative">
    <ion-img class="sport-picture" :src="sport.get('photo').url()" />
    <div class="absolute z-10 bottom-0 left-0 p-16 mb-12">
      <p class="name">{{sport.get('translations').de.name.toUpperCase()}}</p>
      <p class="desc">{{sport.get('translations').de.desc}}</p>
      <button class="tour-button">{{sport.id === id ? 'Coming soon' : 'Tour planen'}}</button>
    </div>
  </div>
</template>

<script>
import { IonImg } from '@ionic/vue';
export default {
  name: 'SportComponent',
  components: {IonImg },
  props: {
    sport: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      id: 'DDjTUJWMXw'
    };
  },
  computed: {},
  mounted() {},
  unmounted() {},
  methods: {
    handleClick() {
      if(this.id === this.sport.id) {
        return;
      }
      this.$emit('tour')
    }
  },
};
</script>
<style scoped>
.sport-component {
  @apply p-0;
  width: 50%;
  height: 100%;
}

.sport-picture {
  @apply h-full object-cover;
  filter: brightness(70%);
}

.name {
  font-size: 3.5rem;
  font-weight: bolder;
}

.desc {
  font-size: 2rem;
  font-weight: bold;
  width: 70%;
}

.tour-button {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  border: white solid 4px;
  font-size: 2.5rem;
  padding-inline: 2rem;
  margin-top: 3rem;
}
</style>
