<template>
  <ion-header class="ion-no-border ion-no-shadow">
    <ion-toolbar>
    </ion-toolbar>
  </ion-header>
  <ion-content color="white" :fullscreen="true" class="max-h-full overflow-y-auto text-primary text-center flex items-center">
    <h1 class="font-bold text-3xl">Hey Abenteurer!</h1>
    <h2 class="mt-5 text-2xl">Bevor es losgeht, noch eine kurze Info:</h2>
    <p class="text-xl mt-3 px-10">Die angezeigten Touren sind Vorschläge, die wir basierend auf deinen Angaben gefunden haben.
      Für das sichere Absolvieren der Tour bist du allerdings selbst verantwortlich.</p>
    <p class="text-xl mt-3 px-10">Höre auf deinen Körper und mache eine Pause, wenn du sie benötigst.
      Informiere dich über die lokalen Notrufnummern und sag deinen Freunden Bescheid, falls du alleine unterwegs bist.
    </p>
      <p class="text-xl mt-3 px-10">Für die Inhalte der Touren sind die Autoren verantwortlich und wir übernehmen keine Haftung für die Richtigkeit der Informationen. Wir übernehmen ebenfalls keine Haftung für Unfälle, Verletzungen, Wetter, Probleme mit den Wanderwegen, den aktuellen Gegebenheiten oder andere unerwartete Vorfälle.</p>
    <p class="text-2xl mt-5">Viel Spaß!</p>


    <ion-button class="mt-10 text-2xl " @click="dismissModal" color="primary">
      Verstanden
    </ion-button>
  </ion-content>
</template>

<script>
import { closeOutline } from 'ionicons/icons';
import { IonContent } from '@ionic/vue';
import { modalController, IonHeader, IonToolbar } from '@ionic/vue';
export default {
  name: 'WarningModal',
  components: { IonContent, IonHeader, IonToolbar },
  props: {
    current: {
      type: Object,
      default: null,
    },

  },
  data() {
    return {
      closeOutline,
    };
  },
  computed: {},
  mounted() {},
  unmounted() {},
  methods: {
    async dismissModal() {
      this.$trackEvent('accept-warning-modal')
      await modalController.dismiss();
    },
  },
};
</script>
