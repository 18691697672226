<template>
  <ion-page>
    <ion-content color="primary" class="">
      <div class="default-background2"></div>
      <div class="grid grid-cols-6 h-full">
        <div class="flex flex-col col-span-3 items-center h-full border-r">
          <p class="text-white text-4xl font-bold px-10 mt-10 text-left w-full">{{ $t('summary_text_1') }}</p>
          <p class="text-white text-3xl font-bold px-10 mb-12 text-left w-full">{{ $t('summary_text_2') }}</p>
          <div class="p-5 w-2/3">
            <MountainProfileOverview
                v-if="showAPRofile"
                :sport-id="summary.object.get('type').id"
                :mountain-profile="summary"
                ref="tourResult"
            />
          </div>
        </div>
        <div class="flex flex-col overflow-y-auto col-span-3 items-center h-full">
          <p class="text-white text-4xl font-bold p-10 mb-12">{{ $t('tour_results') }}</p>
          <div class="flex flex-col">
            <TourCard @click="onTour(tour, index)" v-for="(tour, index) in tours" :key="tour.id" :tour="tour" />
          </div>
        </div>
      </div>
      <TourModal :tour="selectedTour" v-if="selectedTour" :images="images" ref="tourModal" />
    </ion-content>
    <v-idle @idle="onIdle" :duration="60" :events="['touchend']" class="hidden" />
  </ion-page>
</template>

<script>
import TourCard from "@/components/molecules/TourCard";
import TourModal from "@/components/modals/TourModal";
import MountainProfileOverview from "@/components/molecules/MountainProfileOverview";
import { navigate, calendar, sunny, map } from 'ionicons/icons';
import { mapGetters } from 'vuex';
import {IonPage, IonContent, modalController} from '@ionic/vue';
import WarningModal from "@/components/modals/WarningModal.vue";
export default {
  name: 'TerminalTourResult',
  components: {  IonPage, IonContent, MountainProfileOverview, TourModal, TourCard },
  props: {},
  data() {
    return {
      navigate,
      calendar,
      sunny,
      map,
      selectedTour: null,
      images: [],
      showAPRofile: false,
    };
  },
  computed: {
    ...mapGetters({ summary: 'mountain-profiles/summary' }),
    ...mapGetters({ tours: 'terminal/tours' }),
  },
  unmounted() {},
  ionViewWillEnter() {
    this.showAPRofile = true;
  },
  async ionViewDidEnter() {

    this.$refs.tourResult.initChart();

    this.currentModal = await modalController.create({
      component: WarningModal,
      swipeToClose: true,
      backdropDismiss: false,
      cssClass: 'ionic-modal-medium-width ionic-modal-rounded'
    });
    return this.currentModal.present();
  },
  ionViewDidLeave() {
    this.$refs.tourResult.removeChart();
    this.showAPRofile = false;
    this.selectedTour = null;
    this.images = [];
  },
  methods: {
    async onIdle() {
      await this.currentModal.dismiss();
      this.$router.push('/terminal');
    },
    async onTour(tour, index) {
      this.selectedTour = tour;
      this.images = await this.selectedTour.relation('media').query().find();
      this.$refs.tourResult.addTourData({name: tour.get('translations')[this.$i18n.locale].name, mProfile: tour.get('mountainProfile')})
      // eslint-disable-next-line vue/valid-next-tick
      await this.$nextTick(() => this.$refs.tourModal && this.$refs.tourModal.show());
      this.$trackEvent('click-tour', {props: {from: 'tour-result', index}})
    },
  },
};
</script>
