export default {
  development: {
    appId: '4knM9eyEu6IMEMx4kCq7rdXiVv3uk3Nxv3YcHy8f',
    jsKey: 'Mkx8W4LzxPyMVz8GN2vvp3j5Wr0Rj18ok9QzI8b1',
    serverUrl: 'guida.b4a.io/',
  },
  production: {
    appId: '4knM9eyEu6IMEMx4kCq7rdXiVv3uk3Nxv3YcHy8f',
    jsKey: 'Mkx8W4LzxPyMVz8GN2vvp3j5Wr0Rj18ok9QzI8b1',
  },
};
