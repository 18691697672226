import Parse from 'parse';

import serverSettings from '../server';
Parse.initialize(
  serverSettings[process.env.NODE_ENV].appId,
  serverSettings[process.env.NODE_ENV].jsKey
);
Parse.serverURL = 'https://guida.b4a.io/';

export default Parse;
