import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n';
import store from './store';
import router from './router';

import { IonicVue } from '@ionic/vue';

import Parse from './config/parse';
import plausible from './plugins/plausible';
import Vidle from 'v-idle-3'
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Theme CSS */
import './theme/custom.css';
import {Device} from "@capacitor/device";





const app = createApp(App)
  .use(IonicVue).use(i18n).use(store)
  .use(router).use(Vidle);

Device.getInfo().then(info => {
  let id;
  if(!info.name) {
    id = 'test';
  } else {
    id = info.name
  }
  console.log(id)
  const plausibleOptions = {
    enableAutoPageviews: true,
    enableAutoOutboundTracking: true,
    domain: "guida-tablet-" + id,
    apiHost: "https://insights.guidasa.de/",
    trackLocalhost: true,
  }
  app.use(plausible, plausibleOptions)
  console.log(app._context)
})
app.config.globalProperties.$parse = Parse;
app.config.globalProperties.$router = router;

store.dispatch('init/loadInit');
router.isReady().then(() => {
  app.mount('#app');
});
