<template>
  <ion-page>
    <ion-content class="flex flex-col h-full overflow-y-hidden" color="primary">
      <div v-if="step !== 0" class="">
        <div class="mp-toolbar">
          <ProgressBar
              v-if="step"
              :title="questions[currentQuestionIndex].category.get('translations')[$i18n.locale].name"
              :perc="currentQuestionIndex / questions.length"
          />
        </div>
      </div>
      <div class="default-background2"></div>
      <div class="flex justify-center h-full items-center flex-col" v-if="step === 0">
        <div class="flex justify-between items-center h-full">
          <SportComponent
            @tour="onSport(sport.id)"
            v-for="sport in sports"
            :key="sport.id"
            :sport="sport"
          />
        </div>
      </div>
      <div
        class="flex w-full mx-auto items-center flex-col justify-center h-5/6"
        v-if="step > 0"
      >
        <component
            :is="getComponentName(question.type)"
            v-for="question in currentQuestion"
            :key="'component' + question.id"
            :questionData="question"
            @done="onDone"
        />
      </div>
    </ion-content>
    <v-idle @idle="onIdle" :duration="60" :events="['touchend']" class="hidden" />
  </ion-page>
</template>

<script>
import TinderSwipe from '../../../components/organisms/TinderSwipe/TinderSwipe';
import ProgressBar from "@/components/molecules/ProgressBar";
import YesNoQuestion from '../../../components/organisms/YesNoQuestion';
import WaveRange from '../../../components/organisms/WaveRange/WaveRange';
import { navigate, calendar, sunny, map } from 'ionicons/icons';
import { mapGetters } from 'vuex';
import {
  IonPage,
  IonContent,
  IonCard,
  IonCardContent,
  loadingController, modalController, IonHeader, IonToolbar
} from '@ionic/vue';
import SportComponent from '../../../components/molecules/SportComponent';
import AdventureProfileIntroModal from "@/components/modals/AdventureProfileIntroModal";
export default {
  name: 'TerminalMountainProfile',
  components: {
    SportComponent,
    IonPage,
    IonContent,
    IonCard,
    IonCardContent,
    TinderSwipe,
    YesNoQuestion,
    WaveRange, IonHeader, IonToolbar, ProgressBar
  },
  props: {},
  data() {
    return {
      navigate,
      calendar,
      sunny,
      map,
      step: 0,
      specs: {},
      quests: [],
      startTime: null,
      questionStartTime: null,
      loading: null,
      currentQuestionIndex: 0,
      result: {},
      currentModal: null,
      sportId: null,
    };
  },
  computed: {
    ...mapGetters({ sports: 'sports/sports' }),
    ...mapGetters({ questions: 'mountain-profiles/mountainProfileQuestions' }),
    currentDate() {
      const d = new Date();
      return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
    },
    currentQuestion() {
      if (this.questions.length > 0) {
        return [this.questions[this.currentQuestionIndex]];
      } else return null;
    },
  },
  async mounted() {
    console.log('mounted')
  },
  unmounted() {
    console.log('unmount')
  },
  async ionViewWillEnter() {
    this.currentModal = await modalController.create({
      component: AdventureProfileIntroModal,
      swipeToClose: true,
    });
    return this.currentModal.present();
  },
  ionViewDidLeave() {
    console.log('leave')
    this.$trackEvent('leave-adventure-profile', {props: {data: `${this.currentQuestionIndex + 1 >= this.questions.length}-${this.questions[this.currentQuestionIndex].question.id}-${new Date().getTime() - this.startTime}`}})
    this.step = 0;
    this.result = {};
    this.sportId = null;
    this.currentQuestionIndex = 0;
    this.startTime = null;
    this.questionStartTime= null;
    this.specs = {};
  },
  methods: {
    async onIdle() {
      await this.currentModal.dismiss();
      this.$router.push('/terminal');
    },
    calculateCelsius(kelvin, str) {
      return `${str} ${Math.round((kelvin - 273.15) * 100) / 100}°`;
    },
    getComponentName(type) {
      switch (type) {
        case 'horizontal-range':
          return 'WaveRange';
        case 'vertical-range':
          return 'WaveRange';
        case 'tinder-swipe':
          return 'TinderSwipe';
        case 'yes-no':
          return 'YesNoQuestion';
        default:
          return '';
      }
    },
    async onDone(value) {
      console.log(value);
      this.$trackEvent('done-adventure-profile-question', {props: {time: new Date().getTime() - this.questionStartTime}})
      this.result[value.categoryId] = value;
      if (this.currentQuestionIndex + 1 !== this.questions.length) {
        this.currentQuestionIndex++;
        this.questionStartTime = new Date().getTime();
      } else {
        const {success, id} = await this.$store.dispatch('mountain-profiles/saveMountainProfile', {
          result: this.result,
          sportId: this.sportId,
        });
        if (success) {
          await this.presentLoading();
          if(!this.$store.getters['auth/deviceInfo']) {
            await this.$store.dispatch('auth/deviceInfo');
          }
          await this.$store.dispatch('terminal/loadMatchingTours', {id, sourceName: this.$store.getters['auth/deviceInfo'].sourceName});
          await this.loading.dismiss();
          this.$router.push('/terminal/tour-result');
        }
      }
    },
    async onSport(sportId) {
      try {
        this.$trackEvent('click-sport', {props: {sportId}})
        await this.$store.dispatch('mountain-profiles/loadMountainProfileQuestions', sportId);
        this.sportId = sportId;
        this.step++;
        this.startTime = new Date().getTime()
        this.questionStartTime = new Date().getTime()
        console.log(this.questions)
      } catch (e) {
        console.log(e);
      }
    },
    async onAnswer(answer) {
      const category = this.quests[this.step - 1].get('category');
      this.specs[category.id] = {
        questionId: this.quests[this.step - 1].id,
        answerId: answer.id,
        value: answer.get('value'),
      };
      if (this.step === this.quests.length) {
        await this.presentLoading();
        this.$store.commit('terminal/setMountainProfile', this.specs);
        await this.$store.dispatch('terminal/loadMatchingTours', this.specs);
        await this.$router.push('/terminal/tour-result');
        await this.loading.dismiss();
      } else {
        this.step++;
      }
    },
    async presentLoading() {
      this.loading = await loadingController.create({
        message: 'Wir suchen für Sie die passenden Touren...',
      });

      await this.loading.present();
    },
  },
};
</script>

<style scoped>
.answer-card {
  max-width: 500px;
  min-width: 500px;
}
.mp-toolbar {
  width: 90%;
}
</style>
