import Parse from 'parse';

/**
 * @class FeedbackModule
 */
const FeedbackModule = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async addMyTourFeedback({}, { feedback, userId, tourId }) {
      try {
        await Parse.Cloud.run('addMyTourFeedback', { userId, feedback, tourId }, {});
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {},
};

export default FeedbackModule;
