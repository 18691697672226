<template>
  <div class="progress-wrapper">
    <div class="progress-top">
      <p class="progress-top-title">{{ title }}</p>
      <p class="progress-top-value">{{ Math.floor(perc * 100) }}%</p>
    </div>
    <div class="progress-bar-wrapper">
      <div class="progress-bar-outer">
        <div class="progress-bar-inner" :style="{ width: perc * 100 + '%' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    perc: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  unmounted() {},
  methods: {},
};
</script>
<style scoped>
.progress-wrapper {
  @apply flex flex-col h-full w-full p-3;
}
.progress-top {
  @apply w-full flex justify-between;
}
.progress-top-title {
  @apply text-3xl font-bold text-white;
}
.progress-top-value {
  @apply text-2xl text-secondary;
}
.progress-bar-wrapper {
  @apply w-full flex justify-center items-center mt-3;
}
.progress-bar-outer {
  @apply h-1 w-full rounded-lg;
  background-color: #ffffff33;
}
.progress-bar-inner {
  @apply bg-secondary transition-all duration-200 h-1 rounded-lg;
}
</style>
