<template>
  <div class="range-wrapper">
    <p class="range-question">{{ questionData.question.get('translations')[$i18n.locale].name }}</p>
    <p class="range-value">
      {{ value }} <span class="range-value-unit">{{ customData.unit }}</span>
    </p>
    <p class="range-sub-value">= {{ levelsDis }} {{ $t(customData.firstDesc) }}</p>
    <p class="range-sub-value">= {{ stepsDis }} {{ $t(customData.secondDesc) }}</p>
    <div ref="rangeElement" id="range-element" class="range-element"></div>
    <div class="flex justify-center items-center p-5">
      <InfoBox
        :color="questionData.question.get('customData').color"
        v-if="questionData.question.get('customData').showInfoBox"
        :text="$t(questionData.question.get('customData').infoText)"
      />
    </div>

    <div class="flex justify-center p-10">
      <ion-button @click="onNext" class="px-5 text-2xl font-bold" color="secondary">weiter</ion-button>
    </div>
  </div>
</template>

<script>
import aHelper from './../../../js/adventure-profile';
import { SVG } from '@svgdotjs/svg.js';
import '@svgdotjs/svg.draggable.js';
import { IonButton } from '@ionic/vue';
import InfoBox from '../../atoms/InfoBox';
export default {
  name: 'WaveRange',
  components: { InfoBox, IonButton },
  props: {
    questionData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log('mounted');
    this.width = this.$refs.rangeElement.offsetWidth;
    this.height = this.$refs.rangeElement.offsetHeight;
    this.customData = this.questionData.question.get('customData');
    this.customData.min = this.questionData.min;
    this.customData.max = this.questionData.max;
    this.initSvg();
  },
  data() {
    return {
      height: null,
      width: null,
      dia: 100,
      customData: {
        min: null,
        max: null,
        firstDesc: '',
        secondDesc: '',
      },
      steps: 10,
      value: 850,
      strokeLength: 3,
      showSvg: false,
    };
  },
  computed: {
    levelsDis() {
      return aHelper.getLevels(this.value, this.questionData.type);
    },
    stepsDis() {
      return aHelper.getSteps(this.value, this.questionData.type);
    },
  },
  unmounted() {},
  methods: {
    initSvg() {
      console.log(this.width);
      console.log(this.height);
      const draw = SVG().addTo('#range-element').size(this.width, this.height);
      for (let i = 0; i < this.width; i += this.steps) {
        for (let j = 0; j < this.strokeLength; j++) {
          const curr = i + j;
          const currentY = this.getCurrentPosition(curr);
          const circle = draw.circle(4);
          circle.move(curr, currentY);
          circle.fill('#ffffffdd');
        }
      }
      const rangeButton = draw
        .circle(this.dia)
        .fill('#82D18A')
        .center(this.width / 10, this.getCurrentPosition(this.width / 10));
      /*
      const minText = draw.text(this.customData.min);
      minText
        .move(2, this.getCurrentPosition(2) + 20)
        .font({ fill: '#82D18A', family: 'Helvetica Neue', size: 15 });
      const maxText = draw.text(this.customData.max + '+');
      maxText
        .move(this.width - 100, this.getCurrentPosition(this.width - 100) - 20)
        .font({ fill: '#82D18A', family: 'Helvetica Neue', size: 15 });
       */
      this.value = Math.round(
        ((((this.width - this.dia) / 10 / (this.width - this.dia)) *
          Math.pow(10, this.customData.roundTo)) /
          Math.pow(10, this.customData.roundTo)) *
          this.customData.max
      );
      const that = this;
      rangeButton.draggable();
      rangeButton.on('dragmove.namespace', function (event) {
        // event.detail.event hold the given data explained below
        // this == rect
        event.preventDefault();
        let x = event.detail.box.x;
        if (x >= that.width - that.dia) {
          x = that.width - that.dia;
        }
        if (x < 0) {
          x = 0;
        }
        const y = that.getCurrentPosition(x + that.dia / 2);
        const handler = event.detail.handler;
        handler.move(x, y - that.dia / 2);
        that.value =
          Math.round(
            (x / (that.width - that.dia)) *
              that.customData.max *
              Math.pow(10, that.customData.roundTo)
          ) / Math.pow(10, that.customData.roundTo);
      });
    },
    getCurrentPosition(x) {
      const a = this.customData.a;
      const b = this.customData.b;
      const c = this.customData.c;
      const m = (-this.height + this.dia * 1.3) / this.width;
      const s = this.height - this.dia;
      let d = m * x + s;
      if (this.questionData.question.get('customData').horizontal) {
        d = this.height / 2;
      }
      return a * Math.sin(b * x + c) + d;
    },
    onNext() {
      const value = this.value;
      const minValue = this.customData.min;
      const maxValue = this.customData.max;
      const answers = [];
      const categoryId = this.questionData.category.id;
      console.log(categoryId);
      const o = {
        value,
        minValue,
        maxValue,
        answers,
        categoryId,
      };
      this.$emit('done', o);
    },
  },
};
</script>

<style scoped>
.range-wrapper {
  @apply flex flex-col h-full w-full;
}
.range-question {
  @apply text-white text-4xl font-bold p-5 mb-5;
}
.range-value {
  @apply text-white font-bold text-5xl text-center mt-10;
}
.range-value-unit {
  @apply text-4xl;
  color: #ffffffdd;
}
.range-sub-value {
  @apply text-center text-xl mt-2;
  color: #ffffffcc;
}
.range-element {
  @apply w-full h-full;
  min-width: 100px;
  min-height: 100px;
}
ion-button {
  --padding-start: 2.5rem;
  --padding-end: 2.5rem;
}

.wave-range-bike {
  background-image: url('./../../../assets/bike.png');
}
</style>
