<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>
        {{ $t('tour_details') }}
      </ion-title>
      <ion-button slot="start" color="white" class="ion-no-shadow" @click="dismissModal">
        <ion-icon color="primary" class="font-bold text-xl" :icon="closeOutline"></ion-icon>
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="flex flex-col max-h-full  overflow-y-auto">
      <span class="text-primary font-bold text-xl mt-10 p-5">{{ $t('name') }}</span>
      <p class="text-xl text-primary p-5">
        {{ current.get('translations')[$i18n.locale].name }}
      </p>
      <span class="text-xl mt-10 text-primary font-bold p-5">{{ $t('short_desc') }}</span>
      <p class="text-xl  text-primary p-5">
        {{ current.get('translations')[$i18n.locale].desc }}
      </p>
  </ion-content>
</template>

<script>
import { closeOutline } from 'ionicons/icons';
import { IonContent, IonButton, IonIcon } from '@ionic/vue';
import { modalController, IonHeader, IonTitle, IonToolbar } from '@ionic/vue';
export default {
  name: 'InventoryItemModal',
  components: { IonIcon, IonContent, IonButton, IonHeader, IonTitle, IonToolbar },
  props: {
    current: {
      type: Object,
      default: null,
    },

  },
  data() {
    return {
      closeOutline,
    };
  },
  computed: {},
  mounted() {},
  unmounted() {},
  methods: {
    async dismissModal() {
      await modalController.dismiss();
    },
  },
};
</script>
