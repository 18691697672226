import Parse from 'parse';

import { multiEntityLoading } from '../mixins/loading-states';
import {Device} from "@capacitor/device";
/**
 * @class InstallationModule
 */
const AuthModule = {
  namespaced: true,
  state: {
    ...multiEntityLoading.state,
    user: null,
    showBottomBar: true,
    deviceInfo: null
  },
  mutations: {
    ...multiEntityLoading.mutations,
    setUser(state, parseUser) {
      const userData = {};
      userData.object = parseUser;
      const o = {};
      o.firstname = parseUser.get('firstname');
      o.lastname = parseUser.get('lastname');
      o.photo = parseUser.get('photo').url();
      userData.data = o;
      state.user = userData;
    },
    setShowBottomBar(state, b) {
      state.showBottomBar = b;
    },
    setDeviceInfo(state, info) {
      state.deviceInfo = info;
    }
  },
  actions: {
    ...multiEntityLoading.actions,
    async registerUser({ commit }, user) {
      try {
        const registeredUser = await Parse.User.signUp(
          user.email,
          user.password,
          { firstname: user.firstname, lastname: user.lastname },
          {}
        );
        commit('setUser', registeredUser);
        return {
          error: false,
          result: null,
        };
      } catch (e) {
        console.log(e);
        return {
          error: true,
          result: e,
        };
      }
    },
    async login({ commit }, user) {
      try {
        const parseUser = await Parse.User.logIn(user.email, user.password, {});
        commit('setUser', parseUser);
        return {
          error: false,
          result: null,
        };
      } catch (e) {
        console.log(e);
        return {
          error: true,
          result: e,
        };
      }
    },
    async loadDeviceInfo({commit}) {
      try {
        const info = await Device.getInfo();
        console.log(info)
        let dName = 'test';
        if(info.name) {
          dName = info.name;
        }
        const terminal = await new Parse.Query('Terminal').equalTo('internalId', dName).first();
        const geo = terminal.get('position')
        const name = terminal.get('cityName')
        const sourceName = terminal.get('sourceName')
        const oo = {};
        oo.geo = geo;
        oo.name = name;
        oo.sourceName = sourceName;
        commit('setDeviceInfo', oo);
      } catch(e) {
        console.log(e);
      }
    }
  },
  getters: {
    ...multiEntityLoading.getters,
    user: (state) => state.user,
    showBottomBar: (state) => state.showBottomBar,
    deviceInfo: (state) => state.deviceInfo
  },
};

export default AuthModule;
