<template>
  <div
    :style="{ borderColor: color }"
    class="border border-white flex flex-row rounded-lg p-2 w-full"
  >
    <div class="flex items-center justify-center mr-2">
      <ion-icon :style="{ color }" size="large" :icon="informationCircleOutline" />
    </div>
    <p :style="{ color }" class="font-bold flex items-center justify-center">
      {{ text }}
    </p>
  </div>
</template>

<script>
import { informationCircleOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/vue';
export default {
  name: 'InfoBox',
  components: { IonIcon },
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#ffffff',
    },
  },
  data() {
    return {
      informationCircleOutline,
    };
  },
  computed: {},
  mounted() {},
  unmounted() {},
  methods: {},
};
</script>
