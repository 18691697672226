<template>
  <ion-page class="">
    <ion-content class="overflow-y-hidden ">
      <div class="absolute top-0 bottom-0 right-0 left-0 custom-box-shadow z-1">

      </div>
      <div class="flex flex-col h-full place-content-between background-image-back z-10" :style="backGround">
        <div class="flex flex-col z-10 h-full">
          <TerminalHeader />
          <div class="flex flex-col h-full mt-20 items-center">
            <p class="text-white text-6xl font-bold text-shadow-index">Lust auf eine Wanderung?</p>
            <div class="flex flex-row justify-between items-center mt-28">
              <div class="home-card left-card">
                <p class="text-white font-bold card-title">Wandertour finden</p>
                <p class="text-white card-desc">Finde schnell und einfach eine Wanderung, die zu Dir passt.</p>
                <ion-button expand="block" class="left-button" @click="onCreateMountainProfile">Starten</ion-button>
              </div>
              <p class="text-2xl font-bold text-white px-40">ODER</p>
              <div class="home-card right-card">
                <p class="text-white font-bold card-title">Durch Touren stöbern</p>
                <p class="text-white card-desc">Durchforste alle Touren, die es in dieser Umgebung gibt.</p>
                <ion-button class="right-button" @click="onShowAllTours">Stöbern</ion-button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <TourModal :tour="selectedTour" :images="images" v-if="selectedTour" ref="tourModal" />
    </ion-content>
  </ion-page>
</template>

<script>
import TourModal from "@/components/modals/TourModal";
import { navigate, calendar, sunny, map, chevronForward } from 'ionicons/icons';
import { mapGetters } from 'vuex';
import {IonPage, IonContent, IonButton} from '@ionic/vue';
import TerminalHeader from "@/components/molecules/TerminalHeader";
export default {
  name: 'TerminalIndex',
  components: { TerminalHeader, IonPage, IonContent, IonButton, TourModal },
  props: {},
  data() {
    return {
      navigate,
      calendar,
      sunny,
      map,
      chevronForward,
      selectedTour: null,
      images: [],
    };
  },
  computed: {
    ...mapGetters({ tours: 'tours/newestTours' }),
    currentDate() {
      const d = new Date();
      return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
    },
    backGround() {
      const deviceInfo = this.$store.getters['auth/deviceInfo'];
      let sourceName;
      if(!deviceInfo) {
        sourceName = '';
      } else {
        sourceName = deviceInfo.sourceName
      }
      switch (sourceName) {
        case 'Tourismus Hörnerdörfer':
          return {
            'background-image': 'url(' + require('../../assets/hornerdorfer.jpg') + ')'
          }
        case 'Schwarzwald Tourismus':
          return {
            'background-image': 'url(' + require('./../../assets/swald.jpg') + ')'
          }
        default:
          return {}
      }
    }
  },
  async ionViewWillEnter() {
    if(!this.$store.getters['auth/deviceInfo']) {
      await this.$store.dispatch('auth/loadDeviceInfo');
    }
    this.$store.dispatch('tours/loadNewestTours', this.$store.getters['auth/deviceInfo'].sourceName);
    this.$store.dispatch('terminal/loadAllMountainProfileQuestions');
    this.$store.dispatch('sports/loadSports');
    await this.$store.dispatch('weather-api/loadWeather', {
      location: {
        lat: this.$store.getters['auth/deviceInfo'].geo.latitude,
        lon: this.$store.getters['auth/deviceInfo'].geo.longitude,
      },
      lang: this.$i18n.locale,
    });

  },

  unmounted() {},
  methods: {
    async onTour(tour) {
      this.selectedTour = tour;
      this.images = await this.selectedTour.relation('media').query().find();
      // eslint-disable-next-line vue/valid-next-tick
      await this.$nextTick(() => this.$refs.tourModal && this.$refs.tourModal.show());
      this.$trackEvent('click-tour', {props: {from: 'home'}})
    },
    calculateCelsius(kelvin, str) {
      return `${str} ${Math.round((kelvin - 273.15) * 100) / 100}°`;
    },
    onCreateMountainProfile() {
      this.$router.push('/terminal/mountain-profile');
    },
    onShowAllTours() {
      this.$router.push('/terminal/all-tours')
    }
  },
};
</script>

<style scoped>
.custom-box-shadow {
  background: rgb(41,73,101);
  background: linear-gradient(0deg, rgba(41,73,101,1) 0%, rgba(41,73,101,0.4) 20%, rgba(41,73,101,0.4) 80%, rgba(41,73,101,1) 100%);
}
.tour-part {
  min-height: 370px;
}
.background-image-back {
  background-position: center center;
  background-size: cover;
}
.text-shadow-index {
  text-shadow: 0px 0px 7px #000000;
}
.home-button {
  --background: #F2A72A;
  --padding-bottom: 2rem;
  --padding-top: 2rem;
  --padding-start: 2rem;
  --padding-end: 2rem;
  --border-radius: 15px;
}

.home-card {
  @apply px-10 pb-20 pt-10 flex flex-col place-content-between;
  height: 394px;
  width: 480px;
  border-radius: 20px;
}

.left-card {
  background-color: rgba(242, 167, 42, 0.8);
}

.right-card {

  background-color: rgba(173, 153, 120, 0.6);
}

.right-button {
  @apply text-2xl;
  --background: white;
  --color: #AD9978;
  --padding-top: 2rem;
  --padding-bottom: 2rem;
}

.card-title {
  font-size: 35px;
}

.card-desc {
  font-size: 27px;
}

.left-button {
  @apply text-2xl;
  --background: white;
  --color: #F2A72A;
  --padding-top: 2rem;
  --padding-bottom: 2rem;
}
</style>
