<template>
  <ion-card class="tour-card">
    <div class="img-wrapper">
      <img class="card-img" :src="getImage" />
    </div>
    <ion-card-header>
      <div class="flex flex-col ion-justify-content-between text-card">
        <div class="flex flex-row ion-justify-content-between">
          <ion-text class="text-card-title">{{
              shortenText(tour.get('translations')[$i18n.locale].name, 70)
          }}</ion-text>
        </div>
      </div>
    </ion-card-header>
  </ion-card>
</template>

<script>
import { timer, arrowForward } from 'ionicons/icons';
import { IonCard, IonCardHeader, IonText } from '@ionic/vue';
export default {
  name: 'TourCard',
  components: { IonCard, IonCardHeader, IonText },
  props: {
    tour: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      images: null,
      timer,
      arrowForward,
    };
  },
  computed: {
    getImage() {
      if (this.images) {
        return this.images.length > 0 ? this.images[0].get('file').url() : '';
      } else return '';
    },
  },
  async mounted() {
    this.images = await this.tour.relation('media').query().find();
    // TODO after creating beforeSave for Media, load only 1 thumbnail image instead of all
  },
  unmounted() {},
  methods: {
    shortenText(text, n) {
      const suffix = text.length > n ? '...' : '';
      return text.substr(0, n) + suffix;
    },
  },
};
</script>

<style>
.card-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.img-wrapper {
  height: 220px;
}
.text-card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--ion-color-primary);
}
.text-card-info {
  font-size: 1rem;
}
.tour-card {
  border-radius: 15px;
  min-width: 350px;
  max-width: 350px;
}
</style>
