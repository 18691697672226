import { multiEntityLoading } from '../mixins/loading-states';
/**
 * @class InstallationModule
 */
const WeatherApiModule = {
  namespaced: true,
  state: {
    ...multiEntityLoading.state,
    weather: null,
    apiKey: '7c00964a1ea0e84650f8ff0774131375',
  },
  mutations: {
    ...multiEntityLoading.mutations,
    setWeather(state, weather) {
      state.weather = weather;
    },
  },
  actions: {
    ...multiEntityLoading.actions,
    async loadWeather({ state, commit }, payload) {
      const url = `https://api.openweathermap.org/data/2.5/weather?lat=${payload.location.lat}&lon=${payload.location.lon}&appid=${state.apiKey}&lang=${payload.lang}`;
      try {
        const response = await fetch(url);
        const responseData = await response.json();
        console.log(responseData);
        commit('setWeather', responseData);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    ...multiEntityLoading.getters,
    weather: (state) => state.weather,
  },
};

export default WeatherApiModule;
