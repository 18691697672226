const chartOptions = {
  plugins: {
    legend: {
      display: false
    },
  },
  elements: {
    line: {
      borderWidth: 3,
    },
  },
  scales: {
    r: {
      angleLines: {
        display: true,
        color: 'rgb(255, 255, 255, 0.4)',
      },
      pointLabels: {
        color: 'white',
      },
      title: {
        color: '#fff',
      },
      min: 0,
      max: 1,
      ticks: {
        display: false,
      },
      grid: {
        color: '#ffffffbb',
        display: true,
        tickWidth: 0,
        lineWidth: 0,
        borderColor: '#ffffffbb',
      },
    },
  },
};
export default chartOptions;
