import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomePage from '../views/HomePage.vue'
import TerminalIndex from '../pages/terminal/index';
import TerminalTourResult from '../pages/terminal/tour-result/index';
import TerminalMountainProfile from '../pages/terminal/mountain-profile/index';
import AllTours from "../pages/terminal/AllTours.vue";
const routes = [
  {
    path: '/',
    redirect: '/terminal'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/terminal',
    component: TerminalIndex,
    name: 'TerminalIndex',
  },
  {
    path: '/terminal/mountain-profile',
    component: TerminalMountainProfile,
    name: 'TerminalMountainProfile',
  },
  {
    path: '/terminal/tour-result',
    component: TerminalTourResult,
    name: 'TerminalTourResult',
  },
  {
    path: '/terminal/all-tours',
    component: AllTours,
    name: 'AllToursPage'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
