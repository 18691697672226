<template>
  <CustomModal ref="modal" class="overflow-y-auto" @close="$emit('close')">
    <div class="flex flex-col">
      <div class="image-wrapper">
        <ion-slides :pager="true" @ionSlideDidChange="onslideChange" class="h-full" :options="slideOpts">
          <ion-slide v-for="image in images" class="h-full w-full" :key="image.id">
            <div class="h-full w-full">
              <img class="object-cover w-full" :src="image.get('file').url()" />
            </div>
          </ion-slide>
        </ion-slides>
      </div>
      <div class="info-wrapper">
        <div class="first-info-wrapper">
          <div class="p-5 border-r">
            <p class="font-bold text-2xl ml-3 mb-3 text-primary">{{$t('what_u_get')}}</p>
            <div
                v-for="prop in Object.keys(tour.get('properties')).filter(el => el !== 'totalHeight')"
                :key="prop"
                class="flex flex-row border-b border-gray-200 p-3 justify-between"
            >
              <span class="text-desc text-xl">{{ $t(prop) }}</span>
              <span class="text-primary font-bold text-xl">{{ tour.get('properties')[prop] }}</span>
            </div>
          </div>
          <div class="p-5 flex items-center justify-center">
            <div class="qr-code">
              <p class="text-lg font-bold text-primary">{{$t('get_tour_on_smartphone')}}</p>
              <QRCodeVue3
                  :dotsOptions="{
                  type: 'square',
                  color: '#294965',
                }"
                  :width="170"
                  :height="170"
                  :value="'https://tour.guidasa.de/tour/' + tour.id"
              />
              <div class="flex flex-row font-bold text-lg items-center">
                <ion-icon class="mr-1" color="success" :icon="checkmark" />
                <ion-text color="success">{{$t('free')}}</ion-text>
              </div>
              <div class="flex flex-row font-bold text-lg items-center">
                <ion-icon class="mr-1" color="success" :icon="checkmark" />
                <ion-text color="success">{{$t('no_account2')}}</ion-text>
              </div>
              <div class="flex flex-row font-bold text-lg items-center">
                <ion-icon class="mr-1 text-xl" color="success" :icon="checkmark" />
                <ion-text color="success">{{$t('start_now')}}</ion-text>
              </div>

            </div>
          </div>
        </div>
        <div class="second-info-wrapper border-t mt-10">
          <p class="font-bold text-2xl p-3 text-primary">{{$t('description')}}</p>
          <p class="p-3 text-primary">{{descPreview}}</p>
          <p @click="onReadMore" class="font-bold underline text-primary text-lg p-3">{{$t('read_more')}}</p>
        </div>
      </div>
    </div>
  </CustomModal>
</template>

<script>
import QRCodeVue3 from 'qrcode-vue3';
import {checkmark} from 'ionicons/icons'
import {IonSlide, IonSlides, IonIcon, IonText, modalController} from '@ionic/vue'
import ModalMixins from '@/mixins/modal';
import CustomModal from "@/components/modals/CustomModal";
import TourDetailModal from "@/components/modals/TourDetailModal";
export default {
  name: 'TourModal',
  components: {CustomModal, IonSlide, IonSlides, IonIcon, IonText, QRCodeVue3},
  mixins: [ModalMixins],
  props: {
    tour: {
      type: Object,
      required: true,
    },
    images: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      slideOpts: {
        speed: 400,
        centeredSlides: true,
        pager: true
      },
      checkmark,
    };
  },
  computed: {
    descPreview() {
      return this.tour.get('translations')[this.$i18n.locale].desc.substring(0, 200) + '...'
    }
  },
   mounted() {
  },
  unmounted() {},
  methods: {
    async onReadMore() {
      const currentModal = await modalController.create({
        component: TourDetailModal,
        cssClass: 'my-custom-class',
        swipeToClose: true,
        componentProps: { current: this.tour },
      });
      this.$trackEvent('click-tour-modal-read-more');
      return currentModal.present();
    },
    onslideChange() {
      this.$trackEvent('swipe-tour-modal-image')
    }
  },
};
</script>

<style scoped>
.swiper-slide img {
  @apply w-full;
}
.image-wrapper {
  height: 380px
}
.info-wrapper {
  @apply p-5 flex flex-col;
}
.first-info-wrapper {
  @apply grid grid-cols-2;
}
.second-info-wrapper {

}
.qr-code {
  @apply flex flex-col justify-center items-center p-4 rounded relative;
}
.coming-soon{
  @apply absolute  text-red-700 font-bold text-2xl text-center flex items-center justify-center;
  transform: rotate(45deg);
  top: 45%;
}
</style>
