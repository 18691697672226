<template>
<ion-page>
  <TourModal :tour="selectedTour" :images="images" v-if="selectedTour" ref="tourModal" />
  <ion-content color="primary" class="">
    <div class="grid grid-cols-4 gap-4">
      <TourCard v-for="tour in tours" @click="onTour(tour)" :key="tour.id" :tour="tour" />
    </div>

    <ion-infinite-scroll v-if="!isAtEnd" @ionInfinite="onLoadMore">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ion-content>
  <v-idle @idle="onIdle" :duration="60" :events="['touchend']" class="hidden" />
</ion-page>
</template>

<script>
import TourModal from "@/components/modals/TourModal.vue";
import {loadingController} from "@ionic/vue";
import TourCard from "@/components/molecules/TourCard.vue";
import { IonPage, IonContent, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/vue';
export default {
  name: 'AllToursPage',
  components: {TourCard, IonPage, IonContent, TourModal, IonInfiniteScroll, IonInfiniteScrollContent},
  data() {
    return {
      loading: null,
      tours: [],
      selectedTour: null,
      images: [],
      offset: 0,
      isAtEnd: false,
    }
  },
  async ionViewWillEnter() {
    if(this.tours.length) {
      this.offset = this.tours.length;
      return;
    }
    await this.showLoading();
    this.tours = await new this.$parse.Query('Tour').limit(16).skip(this.offset).equalTo('sourceName', this.$store.getters['auth/deviceInfo'].sourceName).find();
    this.offset += 16;
    await this.loading.dismiss();
  },
  ionViewWillLeave() {
    this.offset = 0;
  },
  methods: {
    onIdle() {
      this.$router.push('/terminal')
    },
    async showLoading()  {
      this.loading = await loadingController.create({
        message: 'Lade Touren in deiner Nähe',
      });

      await this.loading.present();
    },
    async onLoadMore(ev) {
      const newTours = await new this.$parse.Query('Tour').limit(16).skip(this.offset).equalTo('sourceName', this.$store.getters['auth/deviceInfo'].sourceName).find();
      if(newTours.length < 16) {
        this.isAtEnd = true;
      }
      this.offset += 16;
      this.tours = [...this.tours, ...newTours];
      ev.target.complete();
    },
    async onTour(tour) {
      this.selectedTour = tour;
      this.images = await this.selectedTour.relation('media').query().find();
      // eslint-disable-next-line vue/valid-next-tick
      await this.$nextTick(() => this.$refs.tourModal && this.$refs.tourModal.show());
      this.$trackEvent('click-tour', {props: {from: 'all-tours'}})
    },
  }
}
</script>
