<template>
  <ion-header class="ion-no-border ion-no-shadow">
    <ion-toolbar></ion-toolbar>
  </ion-header>
  <ion-content color="primary" :fullscreen="true">
    <div class="default-background"></div>
    <div class="outer-wrapper-slider">
      <ion-slides
        class="h-full"
        @ionSlideWillChange="onSlideChange"
        :pager="true"
        :options="sliderOptions"
      >
        <ion-slide class="custom-slide-wrapper slide">
          <div class="custom-slide-img-wrapper">
            <img
              class="custom-slide-img"
              :src="require('./../../assets/logos_png/Logo_transparent_typo.png')"
            />
          </div>
          <div class="flex flex-col">
            <ion-text class="custom-slide-title" color="light">{{
              $t('adventure_intro_title_1')
            }}</ion-text>
            <ion-text color="light" class="custom-slide-text"
              >{{ $t('adventure_intro_text_1') }}
            </ion-text>
          </div>
        </ion-slide>
        <ion-slide class="custom-slide-wrapper slide">
          <ion-text class="custom-slide-title">{{ $t('why_adventure_profile') }}</ion-text>
          <div class="reason-wrapper">
            <div class="flex items-center justify-center">
              <ion-icon class="adventure-intro-icon" :icon="checkmarkCircleOutline" />
            </div>

            <ion-text color="light" class="custom-slide-text-2"
              >{{ $t('why_adventure_profile_reason_1') }}
            </ion-text>
          </div>
          <div class="reason-wrapper">
            <div class="flex items-center justify-center">
              <ion-icon class="adventure-intro-icon" :icon="funnelOutline" />
            </div>

            <ion-text color="light" class="custom-slide-text-2"
              >{{ $t('why_adventure_profile_reason_2') }}
            </ion-text>
          </div>
          <div class="reason-wrapper">
            <div class="flex items-center justify-center">
              <ion-icon class="adventure-intro-icon" :icon="peopleOutline" />
            </div>

            <ion-text color="light" class="custom-slide-text-2"
              >{{ $t('why_adventure_profile_reason_3') }}
            </ion-text>
          </div>
          <div class="reason-wrapper">
            <div class="flex items-center justify-center">
              <ion-icon class="adventure-intro-icon" :icon="lockClosedOutline" />
            </div>
            <ion-text color="light" class="custom-slide-text-2"
              >{{ $t('why_adventure_profile_reason_4') }}
            </ion-text>
          </div>
        </ion-slide>
        <ion-slide class="custom-slide-wrapper slide">
          <div class="custom-slide-img-wrapper">
            <img
              class="custom-slide-img"
              :src="require('./../../assets/logos_png/Logo_transparent_typo.png')"
            />
          </div>
          <ion-text class="custom-slide-title" color="light">{{
            $t('adventure_profile_outro_title')
          }}</ion-text>
          <ion-text color="light" class="custom-slide-text">{{
            $t('adventure_profile_outro_text')
          }}</ion-text>
          <ion-button @click="dismissModal" color="secondary" class="mt-3">{{
            $t('creat_now')
          }}</ion-button>
        </ion-slide>
      </ion-slides>
      <div class="skip-button w-full">
        <p @click="onSkip" class="skip-text">{{ $t('skip') }}</p>
      </div>
    </div>
  </ion-content>
</template>
<script>
import {
  closeOutline,
  close,
  checkmarkCircle,
  add,
  funnelOutline,
  peopleOutline,
  checkmarkCircleOutline,
  statsChartOutline,
  lockClosedOutline,
  people,
} from 'ionicons/icons';
import {
  IonContent,
  IonHeader,
  IonText,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonIcon,
  IonButton,
} from '@ionic/vue';
import { modalController } from '@ionic/vue';
export default {
  name: 'AdventureProfileIntroModal',
  components: {
    IonContent,
    IonText,
    IonHeader,
    IonToolbar,
    IonSlides,
    IonSlide,
    IonButton,
    IonIcon,
  },
  props: {
    again: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      closeOutline,
      add,
      checkmarkCircleOutline,
      statsChartOutline,
      lockClosedOutline,
      checkmarkCircle,
      people,
      close,
      funnelOutline,
      peopleOutline,
      activeIndex: 0,
      sliderOptions: {
        centeredSlidesBounds: true,
        spaceBetween: 20,
        pager: true,
      },
    };
  },
  computed: {},
  async mounted() {},
  unmounted() {},
  methods: {
    async dismissModal() {
      this.$trackEvent('click-done-intro-modal')
      await modalController.dismiss();
    },
    async onSlideChange(ev) {
      this.activeIndex = await ev.target.getActiveIndex();
    },
    async onSkip() {
      this.$trackEvent('click-skip-intro-modal', {props: {index: this.activeIndex}})
      await modalController.dismiss();
    },
  },
};
</script>

<style scoped>
.custom-slide-wrapper {
  @apply flex flex-col h-full;
}

.custom-slide-title {
  @apply font-bold text-3xl;
}

.custom-slide-img-wrapper {
  @apply flex justify-center items-center h-64;
  max-width: 100vw;
}

.custom-slide-img {
  object-fit: cover;
  width: 60%;
}
.custom-slide-text {
  @apply mt-5 p-2 text-xl;
}

.login-center {
  height: 100%;
  width: 100%;
}
.skip-button {
  @apply flex absolute bottom-0 right-0 left-0 pb-2 justify-center;
}
.swiper-slide {
  justify-content: unset;
}
.outer-wrapper-slider {
  height: 90%;
}
ion-slides {
  --bullet-background-active: var(--ion-color-secondary);
  --bullet-background: white;
}
.skip-text {
  color: #ffffffcc;
}
.reason-wrapper {
  @apply flex justify-start items-center flex-row p-3 w-4/5 mt-10;
}
.adventure-intro-icon {
  @apply text-4xl text-secondary mr-3;
}
.custom-slide-text-2 {
  @apply text-white text-lg text-left;
}
</style>
