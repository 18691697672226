<template>
  <div class="flex flex-row justify-between p-5 text-white font-bold text-xl">
    <div class="flex flex-col place-content-between">
      <div class="flex my-1 flex-row items-center">
        <ion-icon :icon="navigate" />
        <p class="ml-3">{{ $store.getters['auth/deviceInfo']?.name }}</p>
      </div>
      <div class="flex my-1 flex-row items-center">
        <ion-icon :icon="calendar" />
        <p class="ml-3">{{ currentDate }}</p>
      </div>
      <div class="flex my-1 flex-row items-center">
        <ion-icon :icon="sunny" />
        <p class="ml-3" v-if="$store.getters['weather-api/weather']">
          {{
            calculateCelsius($store.getters['weather-api/weather'].main.temp_min, 'Min.')
          }}
          {{
            calculateCelsius($store.getters['weather-api/weather'].main.temp_max, 'Max.')
          }}
        </p>
      </div>
    </div>
    <div>
      <AppLogo logo="logo" size="terminal" />
    </div>
  </div>
</template>

<script>
import { navigate, calendar, sunny, map } from 'ionicons/icons';
import {IonIcon} from '@ionic/vue'
import AppLogo from "@/components/atoms/AppLogo";
export default {
  name: 'TerminalHeader',
  components: {AppLogo, IonIcon},
  props: {},
  data() {
    return {
      navigate, calendar, sunny, map
    };
  },
  computed: {
    currentDate() {
      const d = new Date();
      return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
    },
  },
  mounted() {},
  unmounted() {},
  methods: {
    calculateCelsius(kelvin, str) {
      return `${str} ${Math.round((kelvin - 273.15) * 100) / 100}°`;
    },
  },
};
</script>
