<template>
  <transition name="modal-layer" duration="800ms" appear>
    <div v-if="isVisible" class="modal-layer" @click="onClickOutside">
      <section :class="extra ? extra : 'w-1/2'" class="modal">
        <button class="modal-close" type="button" @click="hide">
          <ion-icon color="primary" class="font-bold text-xl" :icon="closeOutline"  />
        </button>
        <div class="modal-scroll">
          <div class="modal-content">
            <slot />
          </div>
          <footer v-if="hasFooter" class="modal-footer">
            <div class="w-1/2">
              <slot name="footer" />
            </div>
            <div class="flex flex-row justify-end w-1/2">
              <slot name="footer-actions" />
            </div>
          </footer>
        </div>
      </section>
      <v-idle @idle="hide" :duration="50" :events="['touchend']" class="hidden" />
    </div>
  </transition>
</template>

<script>
import {closeOutline} from 'ionicons/icons';
import {IonIcon} from '@ionic/vue'
export default {
  name: 'CustomModal',
  components: { IonIcon },
  props: {
    title: {
      type: String,
      default: '',
    },
    extra: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isVisible: false,
      closeOutline,
    };
  },
  computed: {
    hasHeaderActions() {
      return !!this.$slots['header-actions'];
    },
    hasFooter() {
      return !!this.$slots.footer || !!this.$slots['footer-actions'];
    },
  },
  mounted() {},
  unmounted() {},
  methods: {
    show() {
      this.isVisible = true;
    },

    hide() {
      this.isVisible = false;
      this.$emit('close');
    },

    onClickOutside(event) {
      const modalElement = this.isVisible && this.$el && this.$el.querySelector('.modal');
      if (modalElement && !modalElement.contains(event.target)) this.hide();
    },
  },
};
</script>

<style scoped>
.modal-layer {
  @apply flex justify-end fixed top-0 left-0 w-full h-full z-50;

}
.modal-layer::before {
  content: '';
  @apply absolute top-0 left-0 w-full h-full bg-opacity-20;
}
.modal-layer-enter-active,
.modal-layer-leave-active {
  transition: visibility 150ms linear;
}
.modal-layer-enter-active::before,
.modal-layer-leave-active::before {
  transition: opacity 150ms linear;
}
.modal-layer-enter,
.modal-layer-leave-to {
  visibility: hidden;
}
.modal-layer-enter::before,
.modal-layer-leave-to::before {
  opacity: 0;
}
.modal {
  @apply flex relative border-l bg-white;
}
.modal-layer-enter-active .modal,
.modal-layer-leave-active .modal {
  transition: transform 300ms cubic-bezier(0.5, 0.5, 0.2, 1);
}
.modal-layer-enter .modal,
.modal-layer-leave-to .modal {
  transform: translateX(100%);
}
.modal-close {
  @apply absolute p-4 pl-6 border bg-white text-white leading-none;

  top: 1.75rem;
  right: 100%;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.modal-close:focus {
  outline: 0;
}
.modal-layer-enter-active .modal-close,
.modal-layer-leave-active .modal-close {
  transition: opacity 800ms cubic-bezier(0.5, 0.5, 0.2, 1);
}
.modal-layer-enter .modal-close,
.modal-layer-leave-to .modal-close {
  opacity: 0;
}

.modal-scroll {
  @apply overflow-auto flex flex-col w-full;
}

.modal-header {
  @apply flex flex-row justify-between items-center mb-5;
}

.modal-title {
  @apply text-xl font-bold text-primary;
}

.modal-content {
  @apply pb-6 flex-grow;
}

.modal-footer {
  @apply flex flex-row justify-between py-3 items-end;
}
</style>
