<template>
  <div class="tinder-swipe-wrapper">
    <p class="tinder-swipe-situation">
      {{ $t('situation') }} {{ currentIndex }} / {{ questionData.answers.length }}
    </p>
    <p class="tinder-swipe-question">
      {{ questionData.question.get('translations')[$i18n.locale].name }}
    </p>
    <div class="grid grid-cols-3 gap-5 h-full p-5">

      <div @click="dropToNo" class="droppable droppable-no" ref="dropzoneNo">
        <div class="flex flex-col justify-center items-center">
          <p class="text-white font-bold text-4xl">{{$t('no')}}</p>
          <ion-icon class="font-bold text-4xl" color="danger" :icon="closeOutline" />
        </div>
        <!--
        <transition-group name="list">
          <div v-for="item in result.nope" :key="item.id">{{item.id}}</div>
        </transition-group>
        -->
      </div>
      <div class="tinder-swipe-cards-wrapper">
        <img
            v-for="(image, index) in images"
            :key="image.id"
            :id="image.id"
            :ref="image.id"
            v-show="index === 0 || index === 1"
            :style="{ zIndex: 30 - (index + 1) }"
            class="tinder-swipe-card"
            :src="image.get('photo').url()"
        />
      </div>
      <div @click="dropToYes" class="droppable droppable-yes" ref="dropzoneYes">
        <div class="flex flex-col justify-center items-center">
          <p class="text-white font-bold text-4xl">{{$t('yes')}}</p>
          <ion-icon class="font-bold text-4xl" color="success" :icon="checkmark" />
          <!--
          <transition-group name="list">
            <div v-for="item in result.yay" :key="item.id">{{item.id}}</div>
          </transition-group>
          -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { IonIcon } from '@ionic/vue';
import {createGesture} from '@ionic/vue'
import { checkmark, closeOutline } from 'ionicons/icons';
export default {
  name: 'TinderSwipe',
  components: {IonIcon },
  props: {
    questionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      checkmark,
      closeOutline,
      currentIndex: 1,
      isMoving: false,
      images: [],
      canDoAction: true,
      result: {
        yay: [],
        nope: [],
      },
      gestureArray: [],
    };
  },
  computed: {},
  mounted() {
    this.images = this.questionData.answers;
    setTimeout(() => {
      for(let i = 0; i < this.images.length; i++) {
        const imageId = this.images[i].id;
        const imageElement = this.$refs[imageId][0]
        // eslint-disable-next-line no-unused-vars
        const gesture = createGesture({
          el: imageElement,
          threshold: 1,
          gestureName: 'my-gesture',
          onStart: ev => {
            console.log(ev);
            imageElement.style.transition = '';
            imageElement.style.opacity = '0.8';
          },
          onMove: ev => {
            console.log(ev);
            imageElement.style.transform = `translate(${ev.deltaX}px, ${ev.deltaY}px)`;
            this.checkDropZoneHover(ev.currentX, ev.currentY, imageElement)
          },
          onEnd: ev => {
            console.log(ev);
            this.handleDrop(imageElement, ev.currentX, ev.currentY, i)
          }
        });
        gesture.enable();
        this.gestureArray.push(gesture);
        console.log(gesture)
      }
    }, 1000)

  },
  unmounted() {},
  methods: {
    dropToNo() {
      this.$trackEvent('click-tinder-swipe')
      this.result.nope.push(this.images[0]);
      this.images = this.images.slice(1, this.images.length);
      if (this.images.length === 0) {
        this.finish();
      }
    },
    dropToYes() {
      this.$trackEvent('click-tinder-swipe')
      this.result.yay.push(this.images[0]);
      this.images = this.images.slice(1, this.images.length);
      if (this.images.length === 0) {
        this.finish();
      }
    },
    checkDropZoneHover(x, y) {
      const dropYes = this.$refs.dropzoneYes
      const dropYesRect = dropYes.getBoundingClientRect()

      const dropNo = this.$refs.dropzoneNo
      const dropNoRect = dropNo.getBoundingClientRect();
      if(this.isInZone(x, y, dropYesRect)) {
        dropYes.classList.add('green-overlay')
      } else {
        dropYes.style.backgroundColor = '';
        dropYes.classList.remove('green-overlay')
      }
      if(this.isInZone(x, y, dropNoRect)) {
        dropNo.classList.add('red-overlay')
      } else {
        dropNo.classList.remove('red-overlay')
      }
    },
    isInZone(x, y, dropZone) {
      if(x < dropZone.left || x>= dropZone.right) {
        return false
      }
      if(y < dropZone.top || y >= dropZone.bottom) {
        return false
      }
      return true;
    },
    handleDrop(item, endX, endY){
      const dropYes = this.$refs.dropzoneYes
      const dropYesRect = dropYes.getBoundingClientRect()

      const dropNo = this.$refs.dropzoneNo
      const dropNoRect = dropNo.getBoundingClientRect();
      if(this.isInZone(endX, endY, dropYesRect)) {
        this.$trackEvent('swipe-tinder-swipe')
        this.result.yay.push(this.images[0]);
        this.images = this.images.slice(1, this.images.length);
      } else if(this.isInZone(endX, endY, dropNoRect)) {
        this.$trackEvent('swipe-tinder-swipe')
        this.result.nope.push(this.images[0]);
        this.images = this.images.slice(1, this.images.length);
      } else {
        item.style.transition = '.2s ease-out';
        item.style.transform = 'translate(0, 0)';
        item.style.opacity = '1';
      }
      dropNo.classList.remove('red-overlay')
      dropYes.classList.remove('green-overlay')
      if (this.images.length === 0) {
        this.finish();
      }
      this.currentIndex++;
    },
    finish() {
      let value = 0;
      for (let i = 0; i < this.result.yay.length; i++) {
        if (this.result.yay[i].get('value') > value) {
          value = this.result.yay[i].get('value');
        }
      }
      const minValue = this.questionData.question.get('min');
      const maxValue = this.questionData.question.get('max');
      const answers = this.result;
      answers.yay = answers.yay.map((el) => el.id);
      answers.nope = answers.nope.map((el) => el.id);
      const categoryId = this.questionData.category.id;
      const o = {
        value,
        minValue,
        maxValue,
        answers,
        categoryId,
      };
      this.$emit('done', o);
    },
    onSwipe(c) {
      if (!this.canDoAction) {
        return;
      }
      this.canDoAction = false;
      const card = document.getElementById(this.images[0].id);
      card.classList.add(c);
      setTimeout(() => {
        this.result[c].push(this.images[0]);
        this.images = this.images.slice(1, this.images.length);
        if (this.images.length === 0) {
          let value = 0;
          for (let i = 0; i < this.result.yay.length; i++) {
            if (this.result.yay[i].get('value') > value) {
              value = this.result.yay[i].get('value');
            }
          }
          const minValue = this.questionData.question.get('min');
          const maxValue = this.questionData.question.get('max');
          const answers = this.result;
          answers.yay = answers.yay.map((el) => el.id);
          answers.nope = answers.nope.map((el) => el.id);
          const categoryId = this.questionData.category.id;
          const o = {
            value,
            minValue,
            maxValue,
            answers,
            categoryId,
          };
          this.$emit('done', o);
        }
        this.currentIndex++;
        this.canDoAction = true;
      }, 300);
    },
  },
};
</script>

<style scoped>
.tinder-swipe-wrapper {
  @apply flex flex-col w-full;
  max-height: 100%;
  height: 100%;
}
.tinder-swipe-situation {
  @apply px-5 pt-5 text-xl;
  color: #ffffff99;
}
.tinder-swipe-question {
  @apply text-white text-4xl font-bold px-5 mb-5;
}
.tinder-swipe-cards-wrapper {
  @apply w-full h-full relative;
}
.tinder-swipe-action-buttons {
  @apply absolute flex flex-row justify-around items-center w-full pb-5 z-50;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(41, 73, 101, 0.6) 0%,
    rgba(41, 73, 101, 0.4822303921568627) 47%,
    rgba(41, 73, 101, 0) 100%
  );
}
.tinder-swipe-action-button {
  @apply w-16 h-16 rounded-full text-white flex justify-center items-center text-3xl;
}

.tinder-swipe-accept-button {
  @apply bg-secondary;
}
.tinder-swipe-decline-button {
  @apply bg-red-500;
}
.tinder-swipe-card {
  @apply h-full absolute w-full top-0 bottom-0 right-0 left-0;
  object-fit: cover;
  border-radius: 30px;
  transform-origin: 50% 99%;
}
@keyframes yay {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(40deg) translateY(-200px) translateX(200px);
  }
}
@keyframes nope {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-40deg) translateY(-200px) translateX(-200px);
  }
}

.yay {
  animation: yay 0.3s ease-out;
}
.nope {
  animation: nope 0.3s ease-out;
}
.droppable {
  @apply border h-full w-full rounded flex justify-center items-center;
}
.green-overlay {
  @apply transition duration-200 transition-all;
  background-color: rgba(0, 255, 0, 0.5);
}
.red-overlay {
  @apply transition duration-200 transition-all;
  background-color: rgba(255, 0, 0, 0.5);
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
