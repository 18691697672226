// plugins/plausible.js
import Plausible from 'plausible-tracker';

export default {
  install: (app, options) => {

    const { enableAutoPageviews, enableAutoOutboundTracking, trackEvent } = Plausible(options);

    enableAutoPageviews();
    enableAutoOutboundTracking();
    app.config.globalProperties.$trackEvent = trackEvent
    app.provide('plausible');
  },
};
