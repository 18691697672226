<template>
  <div class="yes-no-wrapper">
    <p class="yes-no-question">
      {{ questionData.question.get('translations')[$i18n.locale].name }}
    </p>
    <ion-card
      class="yes-no-answer"
      v-for="answer in questionData.answers"
      :key="answer.id"
      @click="onAnswer(answer)"
    >
      <ion-card-content class="flex justify-center">
        <p class="text-center yes-no-answer">{{ answer.get('translations')[$i18n.locale].name }}</p>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import { IonCard, IonCardContent } from '@ionic/vue';
export default {
  name: 'YesNoQuestion',
  components: { IonCard, IonCardContent },
  props: {
    questionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  unmounted() {},
  methods: {
    onAnswer(answer) {
      const value = answer.get('value');
      const minValue = 0;
      const maxValue = 1;
      const answers = [answer];
      const categoryId = this.questionData.category.id;
      console.log(categoryId);
      const o = {
        value,
        minValue,
        maxValue,
        answers: answers.map((el) => el.id),
        categoryId,
      };
      this.$emit('done', o);
    },
  },
};
</script>

<style scoped>
.yes-no-wrapper {
  @apply flex flex-col;
}
.yes-no-question {
  @apply text-white text-4xl font-bold px-5 mb-5;
}
.yes-no-answer {
  @apply text-2xl font-bold;
}
</style>
