<template>
  <div class="flex justify-end">
    <img
      :class="size"
      v-if="logo === 'logo'"
      :src="require('../../assets/logos_png/guida__signet_white.png')"
    />
    <img
      :class="size"
      v-if="logo === 'title'"
      :src="require('../../assets/logos_png/guida_logo_typo_light-transparent2.png')"
    />
    <img
      :class="size"
      v-if="logo === 'desc'"
      :src="require('../../assets/logos_png/Logo_transparent_typo.png')"
    />
  </div>
</template>

<script>
export default {
  name: 'AppLogo',
  components: {},
  props: {
    size: {
      type: String,
      required: true,
    },
    logo: {
      type: String,
      default: 'logo',
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  unmounted() {},
  methods: {},
};
</script>

<style scoped>
.small {
  height: 2rem;
  color: white;
}
.medium {
  height: 4rem;
  color: white;
}
.big {
  height: 8rem;
  color: white;
}
.terminal {
  height: 8rem;
}
</style>
