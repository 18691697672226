import { createStore } from 'vuex';

import AuthModule from './modules/auth';
import ToursModule from './modules/tours';
import StoreModules from './store-modules';
import MountainProfilesModule from './modules/mountain-profiles';
import SportsModule from './modules/sports';
import TourPlanningModule from './modules/tour-planning';
import WeatherApiModule from './modules/weather-api';
import InitModule from './modules/init';
import PackingListsModule from './modules/packing-lists';
import TerminalModule from './modules/terminal';
import ShortInfosModule from './modules/short-info';
import FeedbackModule from './modules/feedback';

const modules = {
  [StoreModules.AUTH]: AuthModule,
  [StoreModules.TOURS]: ToursModule,
  [StoreModules.MOUNTAINPROFILES]: MountainProfilesModule,
  [StoreModules.SPORTS]: SportsModule,
  [StoreModules.TOURPLANNINGMODULE]: TourPlanningModule,
  [StoreModules.WEATHERAPI]: WeatherApiModule,
  [StoreModules.INITMODULE]: InitModule,
  [StoreModules.PACKINGLISTSMODULE]: PackingListsModule,
  [StoreModules.TERMINALMODULE]: TerminalModule,
  [StoreModules.SHORTINFOSMODULE]: ShortInfosModule,
  [StoreModules.FEEDBACKMODULE]: FeedbackModule,
};

export default new createStore({
  modules,
});
