import Parse from 'parse';

import { multiEntityLoading } from '../mixins/loading-states';
/**
 * @class InstallationModule
 */
const MountainProfilesModule = {
  namespaced: true,
  state: {
    ...multiEntityLoading.state,
    mountainProfiles: {},
    mountainProfileQuestions: null,
    summary: null,
    mountainProfilesCharts: {},
  },
  mutations: {
    ...multiEntityLoading.mutations,
    setMountainProfiles(state, profiles) {
      state.mountainProfiles = profiles;
    },
    setMountainProfileQuestions(state, questions) {
      state.mountainProfileQuestions = questions;
    },
    setNewMountainProfileToMountainProfiles(state, profile) {
      state.mountainProfiles[profile.object.get('type').id] = profile;
    },
    setSummary(state, profile) {
      state.summary = profile;
    },
    setMountainProfileChart(state, { id, chart }) {
      state.mountainProfilesCharts[id] = chart;
    },
  },
  actions: {
    ...multiEntityLoading.actions,
    async loadMountainProfiles({ commit }) {
      try {
        const data = await Parse.Cloud.run('loadCurrentMountainProfiles', {}, {});
        if (!data.error) {
          commit('setMountainProfiles', data.result);
        } else {
          console.log(data.result);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async loadMountainProfileQuestions({ commit }, sportId) {
      try {
        const q = await Parse.Cloud.run('loadMountainProfileQuestions', { sportId }, {});
        commit('setMountainProfileQuestions', q);
      } catch (e) {
        console.log(e);
      }
    },
    async saveMountainProfile({ commit }, data) {
      try {
        console.log(data);
        const profile = await Parse.Cloud.run(
          'saveMountainProfile',
          { mountainProfileData: data },
          {}
        );
        commit('setNewMountainProfileToMountainProfiles', profile);
        commit('setSummary', profile);
        return {success: true, id: profile.object.id};
      } catch (e) {
        console.log(e);
        return {success: false}
      }
    },
  },
  getters: {
    ...multiEntityLoading.getters,
    mountainProfiles: (state) => state.mountainProfiles,
    mountainProfileQuestions: (state) => state.mountainProfileQuestions,
    summary: (state) => state.summary,
    mountainProfilesCharts: (state) => state.mountainProfilesCharts,
  },
};

export default MountainProfilesModule;
