<template>
  <div class="flex flex-col w-full p-2">
    <div class="flex flex-col">
      <div class="text-secondary flex flex-row items-center font-bold text-2xl mb-5">
        <div class="my-profil"></div>
        <p>Dein Profil</p>
      </div>
      <div class="orange-color flex flex-row items-center font-bold text-2xl">
        <div class="tour-profil"></div>
        <p>Profil der Tour</p>
      </div>
    </div>
    <div class="w-full h-full" :class="{ hidden: !state }">
      <vue3-chart-js
        v-if="chartData.datasets.length"
        :id="
          'adventureProfile' +
          adventureProfiles[this.sportId].object.get('type').get('translations').de.name
        "
        :ref="
          'adventureProfile' +
          adventureProfiles[this.sportId].object.get('type').get('translations').de.name
        "
        type="radar"
        :data="chartData"
        :options="chartOptions"
      >
      </vue3-chart-js>
    </div>
  </div>
</template>

<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs';
import chartOptions from '../../js/chart-options';
import { walk, bicycle, swapHorizontalOutline } from 'ionicons/icons';
import { mapGetters } from 'vuex';
export default {
  name: 'MountainProfileOverview',
  components: {
    Vue3ChartJs,
  },
  props: {
    mountainProfile: {
      type: Object,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    sportId: {
      type: String,
      default: '',
    },
    saveInStore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      state: true,
      swapHorizontalOutline,
      chartOptions,
      chartData: {
        labels: [],
        datasets: [],
      },
    };
  },
  computed: {
    ...mapGetters({ adventureProfiles: 'mountain-profiles/mountainProfiles' }),
    getIcon() {
      switch (this.mountainProfile.object.get('type').get('translations').de.name) {
        case 'Wandern':
          return walk;
        case 'E-Bike':
          return bicycle;
      }
      return walk;
    },
  },
  unmounted() {
    console.log('unmount')
  },
  methods: {
    initChart() {
      console.log(this.adventureProfiles[this.sportId].object.id)
      const keys = Object.keys(this.adventureProfiles[this.sportId].object.get('resultShort'));
      const result = {
        labels: [],
        dataSets: [],
      };
      for (let i = 0; i < keys.length; i++) {
        const curr = this.adventureProfiles[this.sportId].object.get('resultShort')[keys[i]];
        result.labels.push(curr.translations.de.name);
        result.dataSets.push(
          Math.floor(((curr.value - curr.minValue) / (curr.maxValue - curr.minValue)) * 100) / 100
        );
      }
      this.chartData.labels = result.labels;
      if(this.chartData.datasets.length > 1) {
        this.chartData.datasets.shift();
        this.updateChart();
      }
      this.chartData.datasets.push({
        label: 'Ihr Adventureprofile',
        data: result.dataSets,
        fill: true,
        backgroundColor: 'rgba(130, 209, 138, 0.8)',
        borderColor: 'rgb(130, 209, 138)',
      });
    },
    updateChart() {
      this.$refs[
        'adventureProfile' +
          this.adventureProfiles[this.sportId].object.get('type').get('translations').de.name
      ].update();
    },
    removeChart() {
      for(let i = 0; i < this.chartData.datasets.length; i++) {
        this.chartData.datasets.shift();
      }
      this.updateChart();
    },
    addTourData({name, mProfile}) {
      console.log(mProfile)
      let keys = Object.keys(this.adventureProfiles[this.sportId].object.get('result'));
      console.log(keys)
      keys = keys.filter(el => el !== "9DUnWSDSEB")
      console.log(keys);
      const tourMp = [];
      for(let i = 0; i < keys.length; i++) {
        console.log('=================================')
        const curr = this.adventureProfiles[this.sportId].object.get('resultShort')[i];
        console.log(curr.translations.de.name +  ' -  ' + keys[i])
        const newT = mProfile[keys[i]];
        console.log('newT: ' + newT)
        console.log('curr.minValue: ' + curr.minValue)
        console.log('curr.maxValue: ' + curr.maxValue)
        tourMp.push(
            Math.floor(((newT - curr.minValue) / (curr.maxValue - curr.minValue)) * 100) / 100
        );
        console.log('=================================')
      }
      const dataSet = {
        label: name,
        data: tourMp,
        fill: true,
        backgroundColor: 'rgba(255, 136, 0, 0.7)',
        borderColor: 'rgb(255, 136, 0)',
      }
      if(this.chartData.datasets.length >1) {
        this.chartData.datasets.shift();
      }

      this.chartData.datasets.unshift(dataSet);
      this.$refs[
      'adventureProfile' +
      this.adventureProfiles[this.sportId].object.get('type').get('translations').de.name
          ].update();
    },
    onSwap() {
      this.state = !this.state;
      this.initChart();
      this.updateChart();
    },
  },
};
</script>

<style scoped>
.mountain-title {
  width: 12em;
}

.orange-color {
  color: rgb(255, 136, 0);
}

.my-profil {
  @apply w-5 h-3 bg-secondary mr-3;
}

.tour-profil {
  @apply w-5 h-3 mr-3;
  background-color: rgb(255, 136, 0);
}
</style>
