function getSteps(n, type) {
  if (type === 'horizontal-range') {
    return Math.round((n * 1000) / 400);
  }
  if (type === 'vertical-range') {
    return Math.round(n * 6);
  }
}
function getLevels(n, type) {
  if (type === 'horizontal-range') {
    return Math.round((n * 1000) / 100);
  }
  if (type === 'vertical-range') {
    return Math.round(n / 3);
  }
}

export default { getSteps, getLevels };
