<template>
  <ion-app>
    <ion-router-outlet />
    <ion-fab  vertical="top" horizontal="end" slot="fixed">
      <div class="flex flex-row">
        <button v-if="$route.path !== '/terminal'" @click="onHome" class="font-bold">
          <ion-icon class="text-white p-2 fab-button-background" :icon="home" />
        </button>
        <button @click="onDataPrivacy" class="font-bold">
          <ion-icon class="text-white p-2 fab-button-background" :icon="informationCircle" />
        </button>
      </div>

    </ion-fab>
  </ion-app>
</template>

<script>
import {home, informationCircle} from 'ionicons/icons'
import {IonApp, IonRouterOutlet, IonFab, modalController} from '@ionic/vue';
import DatenschutzModal from "@/components/modals/DatenschutzModal.vue";
export default{
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet, IonFab
  },
  data() {
    return {
      home,
      currentModal: null,
      informationCircle
    }
  },
  methods: {
    onHome() {
      this.$router.push('/terminal')
    },
    async onDataPrivacy() {
      this.currentModal = await modalController.create({
        component: DatenschutzModal,
        swipeToClose: true,
        cssClass: 'ionic-modal-medium-width'
      });
      return this.currentModal.present();
    }
  }
};
</script>

<style scoped>

.fab-button-background {
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 3rem;
}
</style>
